import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { Context } from "/home/ubuntu/hff/src/Context/Context";
import { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import SearchIcon from '@mui/icons-material/Search';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import UpgradePlanDialog from './CommonComponents/UpgradePlanDialog.react';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


const GET_USER_PRODUCT_LIST_COUNT = gql`
  query getUserProductListCount {
    getUserProductListCount
  }
`;

const GET_ORDER_LIST_COUNT = gql`
  query getOrderListCount {
    getOrderListCount
  }
`;

const GET_SHOW_UPGRADE_ICON = gql`
query getShowUpgradeIcon {
  getShowUpgradeIcon
}
`;

export default function BasicList() {
  const { setState, state } = useContext(Context);
  const { sendLog, firstName, isOpenYellowBanner } = state;
  const storePlatform = localStorage.getItem("storePlatform");
  const navigate = useNavigate();
  const handleClickList = (path) => {
    setState({ ...state, title: "" });
    if (window.location.pathname === path) {
      navigate("/testPathname");
      setTimeout(() => {
        navigate(path);
      }, 10);
    } else {
      navigate(path);
    }
  };

  const siderbarList = [
    //
    [{
      icon: <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px", }} />,
      selectIcon: <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
      title: "Products Search",
      path: "/app",
      type: "route"
    }, {
      icon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_importURL.png"
          width="20px"
          height="18px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      selectIcon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_importURL.png"
          width="20px"
          height="18px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      title: "Import by URL",
      path: "",
      type: "event",
      function: () => {
        sendLog("PRODUCT_SEARCH_TAB_CLICK");
        setState({
          ...state,
          isOpenImportAliExpressURLDialog: true
        })
      }
    }, {
      icon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_ChromeExtension.png"
          width="19.32px"
          height="19.31px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      selectIcon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_ChromeExtension.png"
          width="19.32px"
          height="19.31px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      title: "Chrome Extension",
      path: "/extensionInstructions",
      type: "route"
    }],
    //
    [{
      icon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_myProducts.png"
          width="19px"
          height="23px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      selectIcon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_myProducts.png"
          width="19px"
          height="23px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      title: "My Products",
      path: "/myProducts",
      type: "route"
    }, {
      icon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_myOrders.png"
          width="20px"
          height="20px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      selectIcon: <Box sx={{
        width: "24px",
        height: "24px",
        marginRight: "16px",
        marginTop: '-12px'
      }}>
        <img
          src="/img/sidebar/sidebar_icon_myOrders.png"
          width="20px"
          height="20px"
          loading={"lazy"}
          alt="sidebar icon"
        />
      </Box>,
      title: "My Orders",
      path: "/myOrders",
      type: "route"
    }],
    //
    [
      //   {
      //   icon: <PeopleAltOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
      //   selectIcon: <PeopleAltOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
      //   title: "Referral",
      //   path: "/referral",
      //   type: "route"
      // },
      {
        icon: <SettingsIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
        selectIcon: <SettingsIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
        title: "Settings",
        path: "/setting",
        type: "route"
      }, {
        icon: <PlayCircleOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
        selectIcon: <PlayCircleOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
        title: "Tutorials",
        path: "/tutorial",
        type: "route"
      }, {
        icon: <Box sx={{
          width: "24px",
          height: "24px",
          marginRight: "16px",
          marginTop: '-12px'
        }}>
          <img
            src="/img/sidebar/sidebar_icon_FAQ.png"
            width="22px"
            height="22px"
            loading={"lazy"}
            alt="sidebar icon"
          />
        </Box>,
        selectIcon: <Box sx={{
          width: "24px",
          height: "24px",
          marginRight: "16px",
          marginTop: '-12px'
        }}>
          <img
            src="/img/sidebar/sidebar_icon_FAQ.png"
            width="22px"
            height="22px"
            loading={"lazy"}
            alt="sidebar icon"
          />
        </Box>,
        title: "FAQs",
        path: "/faq",
        type: "route"
      }, {
        icon: <MailOutlineIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
        selectIcon: <MailOutlineIcon sx={{ color: "rgba(0, 0, 0, 0.56)", width: '24px', height: '24px', marginRight: "16px" }} />,
        title: "Contact Us",
        path: "",
        type: "event",
        function: () => {
          document.querySelector('div[title="Contact us button"]').click();
        }
      }]]
  const { loading: myProductCountLoading, data: myProductCountData, refetch } = useQuery(GET_USER_PRODUCT_LIST_COUNT, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { loading: myOrderCountLoading, data: myOrderCountData, refetch: myOrderRefetch } = useQuery(GET_ORDER_LIST_COUNT, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { data: showUpgradeIcon } = useQuery(GET_SHOW_UPGRADE_ICON, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    if (myProductCountLoading === false && myProductCountData != null && myProductCountData.getUserProductListCount !== -1) {
      setState({
        ...state,
        handleRefetchMyProductsCount: refetch,
        myProductListCount: myProductCountData.getUserProductListCount,
      })
    }
  }, [myProductCountLoading]);
  useEffect(() => {
    if (myOrderCountLoading === false && myOrderCountData != null && myOrderCountData.getOrderListCount === -2) {
      setState({
        ...state,
        isOpenRefetchTokenDialog: true
      });
    }
  }, [myOrderCountLoading]);
  const { pathname } = useLocation();
  return (
    <Box sx={{
      backgroundColor: "#FFF",
      width: "240px",
      height: "100%",
      position: "fixed",
      top: isOpenYellowBanner ? "68px" : 0,
      left: 0,
      overflow: "auto",
      boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      zIndex: '99'
    }} >
      <Box sx={{ height: "70px" }}></Box>
      <Box>
        {siderbarList.map((group, groupIndex) => {
          return <Box key={"group" + groupIndex} sx={{
            borderTop: groupIndex === 0 ? "none" : "2px solid #e0e0e0",
            backgroundColor: "#FFF",
          }}>
            {group.map((siderbar, siderbarIndex) => {
              if (storePlatform == "wix" && siderbar.title == "My Orders") {
                return null;
              }
              return <Box sx={{
                height: '48px',
                lineHeight: '48px',
                padding: '0px 4px 0px 12px',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.15px',
                color: "rgba(0, 0, 0, 0.87)",
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'relative',
                backgroundColor: pathname === siderbar.path ? "#D9D9D9" : "#FFF",
                "&:hover": {
                  backgroundColor: pathname === siderbar.path ? "#D9D9D9" : '#F1F2F3',
                }
              }} key={"siderbar" + siderbarIndex} onClick={() => {
                if (siderbar.type === "route") {
                  handleClickList(siderbar.path);
                } else if (siderbar.type === "event") {
                  siderbar.function();
                }
              }}>
                {siderbar.icon}
                <Box>{siderbar.title}</Box>
                {siderbar.path === "/myProducts" && myProductCountLoading === false && myProductCountData != null && myProductCountData.getUserProductListCount !== -1 ?
                  <Box sx={{
                    height: '20px',
                    maxWidth: '44px',
                    padding: '0 6px',
                    position: 'absolute',
                    top: '14px',
                    right: '5px',
                    borderRadius: '6px',
                    backgroundColor: pathname === "/myProducts" ? '#999999' : '#FF7D45',
                    fontSize: '14px',
                    fontWeight: '400',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: "#FFF",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}>{myProductCountData.getUserProductListCount}</Box>
                  : <Box />
                }
                {siderbar.path === "/myOrders" && myOrderCountLoading === false && myOrderCountData != null && myOrderCountData.getOrderListCount !== -1 && myOrderCountData.getOrderListCount !== -2 ?
                  <Box sx={{
                    height: '20px',
                    maxWidth: '44px',
                    padding: '0 6px',
                    position: 'absolute',
                    top: '14px',
                    right: '5px',
                    borderRadius: '6px',
                    backgroundColor: pathname === "/myOrders" ? '#999999' : '#FF7D45',
                    fontSize: '14px',
                    fontWeight: '400',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: "#FFF",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}>{myOrderCountData.getOrderListCount >= 10 ? "10+" : myOrderCountData.getOrderListCount}</Box>
                  : <Box />
                }
                {siderbar.type === "route" && siderbar.path === "" ? <Box sx={{
                  width: '73px',
                  height: '18px',
                  borderRadius: '2px',
                  background: "#c1c1c1",
                  color: '#FFF',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '0.15px',
                  textAlign: 'center',
                  lineHeight: '18px',
                  marginLeft: '20px',
                  padding: '0px 10px',
                  position: 'absolute',
                  top: '14px',
                  right: '5px',
                }}>Coming soon</Box> : null}
                {siderbar.title === "FAQs" || siderbar.title === "Tutorials" || siderbar.title === "Referral" ? <Box sx={{
                  height: '20px',
                  maxWidth: '44px',
                  padding: '0 6px',
                  position: 'absolute',
                  top: '14px',
                  right: '5px',
                  borderRadius: '6px',
                  backgroundColor: '#FF7D45',
                  fontSize: '14px',
                  fontWeight: '400',
                  textAlign: 'center',
                  lineHeight: '20px',
                  color: "#FFF",
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}>New</Box> : null}
              </Box>
            })}
          </Box>
        })}
        <Box sx={{ height: '48px', width: '1px' }}></Box>
      </Box>
      {showUpgradeIcon != null && showUpgradeIcon.getShowUpgradeIcon == true ? <UpgradePlanDialog /> : null}
    </Box >
  )
}