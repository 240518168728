/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getCookie } from "doc-cookie";
import ApolloLinkTimeout from 'apollo-link-timeout';

const root = ReactDOM.createRoot(document.getElementById("root"));
const timeoutLink = new ApolloLinkTimeout(60000);

let httpLink = createHttpLink({
  uri: "https://" + window.location.hostname + "/api",
  //uri: "http://ec2-54-147-214-236.compute-1.amazonaws.com/api"
  // uri: "http://ec2-54-162-83-240.compute-1.amazonaws.com/api"
  // uri: "http://ec2-18-207-127-85.compute-1.amazonaws.com/api"
});
const timeoutHttpLink = timeoutLink.concat(httpLink);

const queryParams = new URLSearchParams(window.location.search);
const isDev = queryParams.get("isDev");

if (isDev != null) {
  httpLink = createHttpLink({
    uri: "http://localhost:4001/api",
  });
}

const token = getCookie("token");
const shopName = localStorage.getItem("shopName");
const storePlatform = localStorage.getItem("storePlatform");

// remove localstorage if cookie expires
if (token == null || token === "null" || token === "undefined") {
  localStorage.clear();
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      token: (token != null) && (token.length > 0) ? token : "",
      // Note this param has to be lower case in header
      shop:
        shopName != null &&
          shopName.length > 0 &&
          token != null &&
          token.length > 0
          ? shopName
          : undefined,
      platform: (storePlatform != null) && (storePlatform.length > 0) ? storePlatform : "",
    },
  };
});

// https://www.apollographql.com/docs/react/pagination/core-api/
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getOrderList: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            const shopifyOrderSet = new Set(
              existing.map((order) => order.orderNumber)
            );
            const uniqueIncoming = [];
            if (incoming != null && incoming.length > 0) {
              for (let i = 0; i < incoming.length; i++) {
                if (!shopifyOrderSet.has(incoming[i].orderNumber)) {
                  uniqueIncoming.push(incoming[i]);
                }
              }
            }
            return [...existing, ...uniqueIncoming];
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(timeoutHttpLink),
  cache,
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
